@import '../../variables.scss';

#education {

  .time {
      font-weight: bold;
    }

  .name {
      color: $important;
      font-weight: bold;
    }

  .school {
      color: $small-text;
      font-size: small;
    }

}