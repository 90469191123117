@import '../../variables.scss';

#contact-form {
  padding: 1.5rem 0;

  #submit {
    background: $new-dark-blue;
    color: $new-white;
  }

  #submit:hover {
    filter: brightness(85%);
  }

  .invalid-feedback {
    color: $icons;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: $side-text;
  }
}