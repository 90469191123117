$font-family-hack: 'Hack', 'Roboto', monospace;
$font-family-pixel: 'VT323', monospace;

//https://colorhunt.co/palette/f1f6f9394867212a3e9ba4b5
$new-white: #F1F6F9;
$new-blue: #394867;
$new-dark-blue: #212A3E;
$new-grey: #9BA4B5;

//$dark: #202426;
//$lighter: #6C733D;
//$light: #9DA65D;
//$gray: #8C8C88;
//$white: #F2F2F2;

$text: #1a1919;
$icons: #a5a8a4;
//$important: #b8485c;
$important: $new-blue;
$small-text: #767876;
$side-text: #585b59;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

.hack {
  font-family: $font-family-hack
}

.pixel {
  font-family: $font-family-pixel
}