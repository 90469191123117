@import '../../variables';

$top-titles:1.4rem;

.project {
  margin-top: 2rem;
  color: $text;

  .left {
    margin-right: 0.5rem;

    .company-name {
      font-size: $top-titles;
    }

    .place {
      color: $important;
    }

    .time {
      color: $small-text;
      font-size: 1rem;
    }

    .remote {
      color: $small-text;
      font-size: 1rem;
    }
  }

  .right {

    .title {
      font-size: $top-titles;
    }

    ul {
      .list-title {
        color: $side-text;
      }
    }

  }
}
