@import '../../variables.scss';

#me {
  width: 110px;
  border: 5px white solid;
  margin: 15px auto;

  @media (min-width: map_get($grid-breakpoints, xs)) and (max-width: map_get($grid-breakpoints, sm)) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

}

#name {
  color: $new-white;
  font-size: xxx-large;
  font-family: 'VT323', monospace;
}

#role {
  color: $new-white;
  font-weight: bold;
  font-size: 1rem;
  margin: -4px 0 0 0;
  float: right;
}

.icon-link {
  color: $new-white;

  :hover {
    color: $new-white;
    filter: brightness(85%);
  }
}

#map-marker {
  color: $new-white;
  border: 2px $new-white solid;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

#icons {
  list-style: none;
  margin: 10px 0 0 -30px;

  li {
    float: left;
    margin: 0 32px 0 0;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  li:before {
    content: none;
  }
}

#names {
  margin-top: 1rem;


  @media (min-width: map_get($grid-breakpoints, xs)) and (max-width: 375px) {
    margin-top: 1rem;
    margin-left: 2rem;
    padding-left: 2rem;
  }

  @media (min-width: 375px ) and (max-width: map_get($grid-breakpoints, sm)) {
    margin-top: 1rem;
    margin-left: 0;
    padding-left: 0;
  }

  @media (min-width: map_get($grid-breakpoints, sm)) and (max-width: map_get($grid-breakpoints, md)) {
    margin-top: 1rem;
  }

  @media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) and (max-width: map_get($grid-breakpoints, xl)) {
    margin-top: 1rem;
  }
}

#map-marker-container {
  margin: 2rem 0;

  @media (min-width: map_get($grid-breakpoints, xs)) and (max-width: map_get($grid-breakpoints, sm)) {
    margin-top: 1rem;
  }

  @media (min-width: map_get($grid-breakpoints, sm)) and (max-width: map_get($grid-breakpoints, md)) {
    margin: 2rem 0 0 -3rem;
  }

  @media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) {
    margin: 2rem 0 0 -1rem;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) and (max-width: map_get($grid-breakpoints, xl)) {
    margin: 2rem 0 0 -2rem;
  }
}

#place-container {
  font-family: $font-family-hack;
  margin: 1.5rem 0 0 -2rem;

  @media (min-width: map_get($grid-breakpoints, xs)) and (max-width: map_get($grid-breakpoints, sm)) {
    margin: 0.5rem 0 0 -2rem
  }
}

#pictures {
  margin-bottom: 1rem;
}

#location-box {
  margin: 2rem 0;
}