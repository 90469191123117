@import '../../variables';

h1.title {
    font-family: $font-family-pixel;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.8rem;
    border-left: 2px solid $important;
    padding-left: 10px;
}

.section {
    margin-bottom: 15px;
}