@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import 'https://cdn.jsdelivr.net/npm/hack-font@3/build/web/hack.css';

body {
  font-family: $font-family-hack;
  background: $new-white;
}


.black {
  background: $new-blue;
  color: $new-white;
}

.inner {
  padding: 0;
}

.content {
  color: $text;
  margin: 1rem 0 2rem 0;
}

ul {
  list-style: none;
  padding-inline-start: 25px;
}

ul li:before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.contact {
  background-color: $new-blue;
}

.move-right-5 {
  margin-left: 5px;
  margin-right: -5px;
}

.move-left-15 {
  margin-left: -15px;
}

.skill {
  cursor: pointer;
}

.skill.hover {
  text-decoration: underline;
}

.contact {
  color: $new-white;

  h1.title {
    border-left: 2px solid $new-dark-blue;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

@media screen {
  .no-screen, .no-screen * {
    display: none !important;
    height: 0;
  }
}

@media print {

  body {
    background: none !important;
    height: 100%;
    width: 100%;
  }

  .project .right .title {
    font-size: 1rem !important;
  }

  .project .left {
    margin-right: -1.1rem!important;
  }

  .project .left .company-name {
    font-size: 1rem !important;
  }

  .project:last-child {
    margin-top: 25px!important;
  }

  .main {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .black {
    color: white !important;
  }

  .contact {
    display: none !important;
    height: 0;
  }

  #names {
    margin-top: 1rem !important;
  }

  .content {
    margin: 0 !important;
  }

  .no-print, .no-print * {
    display: none !important;
    height: 0;
  }

  .side-project ul li:first-child {
    display: none;
  }

  #languages {
    margin-top: 0 !important;;
    margin-left: -3.9rem !important;
    padding-inline-start: 25px !important;;
  }

  .side-projects {
    display: none;
  }


  li.projects {
    display: none !important;
  }

  .project {
    margin-top: 0.5rem !important;
  }

  .technology {
    .list-title {
      font-size: 0.8rem !important;
    }
  }

  .skill {
    font-size: 0.8rem !important;
  }

  .skill-mysql {
    display: none !important;
  }

  .skill-mariadb {
    display: none !important;
  }

  .skill-ansible {
    display: none !important;
  }

  .skill-memcached {
    display: none !important;
  }

  .skill-bazel {
    display: none !important;
  }

  .skill-openshift {
    display: none !important;
  }

  .skill-monix {
    display: none !important;
  }

  .skill-postgresql {
    display: none !important;
  }


  .skill-like {
    font-size: 0.8rem !important;
  }


  #picture-col {
    margin-left: -3.2rem;
  }

  #names {
    margin-left: -3rem;
    margin-top: 0 !important;
  }

  #location-box {
    margin-top: 0!important;
    margin-bottom: 2rem!important;
    margin-left: 6rem!important;
  }

  #icons {
    list-style: none;

    margin-top: 0!important;

    li {
      margin: 8px 32px 0 0!important;
    }

    .icon {
      float: left;
      margin-right: 5px!important;
    }

    .icon-link {
      font-size: 0.8rem!important;
      float: right;
      margin-top: 0.5rem!important;
    }
  }

  #place-container {
    font-size: 0.8rem!important;
    margin: 1.7rem 0 0 -2rem!important;
  }

  #contact-info-container {
    font-family: $font-family-hack;
    font-size: 0.8rem!important;
    margin: 1.2rem 0 0 -2rem;
  }
}
