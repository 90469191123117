@import '../../variables.scss';

#languages {
  margin-top: 1rem;
  margin-left: -1rem;
  padding-inline-start: 0;

  span {
    float: left;
    width: 100px;
    display: inline;

    @media (min-width: map_get($grid-breakpoints, xs)) and (max-width: map_get($grid-breakpoints, sm)) {
      width: 50px;
      margin-left: -1rem;
    }

    @media (min-width: map_get($grid-breakpoints, sm)) and (max-width: map_get($grid-breakpoints, md)) {
      width: 50px;
    }


  }

  strong {
    margin-left: 1rem;
    clear: right;
  }
}