@import '../../variables';


.project {

  .project-name.clickable {
    cursor: pointer;
  }

  .project-name.clickable:hover {
    text-decoration: underline;
  }
}